body.home {
  > footer {
    box-shadow: none;
  }

  > main {
    padding-bottom: 0;

    > .section > h2 {
      @extend .section-title;
    }

    > .news {

      // Assuming it goes right before footer
      flex: 1;
      border-bottom: 0;
      background: $headerFooterBackgroundColor;
      box-shadow: 0 10px 20px -20px $mainShadowColor inset;

      .items {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: stretch;
        padding-top: 1em;
        padding-bottom: 1em;
      }

      .news-item-card {
        max-width: 90vw;
        border-radius: .5em;
        padding: 0 $homeSectionItemSidePadding;
        background: #fff;
        margin-right: 1em;
        margin-bottom: 1em;
        box-shadow: 1px 1px 20px -5px rgba(black, 0.3);

        min-width: 320px;
        @media screen and (min-width: $bigscreenBreakpoint) {
          min-width: unset;
          flex-basis: 20em;
        }

        &.news-item--with-illustration {
          position: relative;

          .title, .excerpt, .meta {
            margin-left: 155px;
          }

          .illustration-container {
            position: absolute;
            top: 0; left: 0; bottom: 0;
            display: block;
            width: 155px;
            overflow: hidden;
            background-size: cover;
            background-position: center center;
          }
        }

        .title {
          font-weight: normal;
          margin-top: .75em;
          margin-bottom: .4em;
          font-size: 110%;
          line-height: 1.3;

          a {
            text-decoration: none;
          }
        }

        .meta {
          font-size: 80%;
          color: lighten($textColor, 50);
        }

        div.excerpt p {
          font-size: 90%;
          line-height: 1.6;
        }
      }
    }
  }
}
