// This style is enabled by nav.js.

$expandTransitionTime: .08s;
$expandTransitionFunc: ease-out;

body.with-expandable-nav {
  > header {
    will-change: background, opacity, box-shadow, height;
    transition:
      color $expandTransitionTime $expandTransitionFunc 0s,
      background $expandTransitionTime $expandTransitionFunc 0s,
      padding-bottom $expandTransitionTime $expandTransitionFunc 0s,
      box-shadow $expandTransitionTime $expandTransitionFunc 0s,
      height $expandTransitionTime $expandTransitionFunc 0s;

    position: relative;
    overflow: hidden;

    padding-bottom: 0;  // JS relies on this value

    .committee-widget {
      .widget-item {
        will-change: color, background, border;
        transition:
          color $expandTransitionTime $expandTransitionFunc 0s,
          background $expandTransitionTime $expandTransitionFunc 0s,
          border $expandTransitionTime $expandTransitionFunc 0s;
      }

      @media screen and (max-width: $bigscreenBreakpoint - 1) {
        .committee-name {
          display: none;
        }
      }

      .widget-group.committee-menu {
        display: none;
      }
    }
  }

  > main {
    will-change: transform;
    transition:
      transform $expandTransitionTime $expandTransitionFunc;
  }

  nav.expandable-nav {
    transition: none;
    opacity: 0;
    display: block;
    position: absolute;
    bottom: 20px;
    display: none;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        padding: 0;
        margin-bottom: 1em;

        &:last-child {
          margin-bottom: 0;
        }

        p.desc {
          margin: 0;
          font-size: 80%;
          opacity: .8;
        }

        @media screen and (min-width: $bigscreenBreakpoint) {
          margin-bottom: 0;
          margin-right: 1em;
          margin-left: 2px;
          max-width: 10em;
          padding-left: 1em;
          border-left: 1px solid white;
        }
      }

      @media screen and (min-width: $bigscreenBreakpoint) {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
      }
    }

    @media screen and (min-width: $bigscreenBreakpoint) {
      left: 15vw;
    }
  }

  button.nav-expand-trigger {
    border: none;
    background: none;
    color: white;

    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    height: 1.8em;
    width: $stripeWidth;
    display: flex;

    position: absolute;
    left: 0;
    top: .5em;

    @media screen and (min-width: $bigscreenBreakpoint) {
      top: 3.3em;
    }

    .fa-times { display: none; }
  }

  &.with-expanded-nav {
    > header {
      box-shadow: 0 5px 50px -20px rgba(black, 0.4);
      background: $isoTCColor;
      color: white;
      padding-bottom: 1.5em;
      z-index: 2;

      .parent-org-reference {
        .logo-link img {
          -webkit-filter: invert(1);
          filter: invert(1);
        }
      }

      nav.priority-nav {
        opacity: 0;
      }
      nav.expandable-nav {
        will-change: opacity;
        transition: opacity $expandTransitionTime $expandTransitionFunc;
        transition-delay: 0s;
        display: block;
        opacity: 1;
      }

      .committee-widget {
        .widget-item {
          background: none;
          box-shadow: none;
          border: 1px solid white;

          &.committee-id {
            background: white;
            color: $isoTCColor;
          }
          &.parent-org-reference {
            background: #e30b1f;
            border-color: white;
            color: white;
          }
        }
        .committee-name {
          display: inline;
          flex-basis: 100%;
        }
        .widget-group {
          flex-basis: 100%;
          @media screen and (min-width: $bigscreenBreakpoint) {
            flex-basis: unset;
          }
        }
        .widget-group.committee-menu {
          display: flex;

          .widget-item {
            background: rgba(black, 0.2);
            border-color: rgba(black, 0.2);
          }
        }
      }
    }
    button.nav-expand-trigger {
      .fa-times { display: inline-block; }
      .fa-bars { display: none; }
    }
  }
}
